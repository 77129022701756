<template>
  <div>
    <top-line title='Актуальные новости ГК "БН-Моторс"' />
    <h2 class="text-center">Новости</h2>
    <news-filter :types="getNewsCategory" class="pb-5" />
    <div class="container-fluid pb-5">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-4 pb-3"
              v-for="news in getNewsByPage"
              :key="news.id"
            >
              <news-item :key="news.id" :news="news" link="news" />
            </div>
          </div>
        </div>
        <div class="col-12 pt-3">
          <pagination
            :active-page="page"
            :count="getCountNews"
            :count-by-page="countByPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopLine from "@/components/pages/TopLine";
import NewsFilter from "@/components/pages/News/NewsFilter";
import { filter } from "lodash";
import NewsItem from "@/components/pages/News/NewsItem";
import Pagination from "@/components/layout/Pagination";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewsPage",
  components: { NewsItem, NewsFilter, TopLine, Pagination },
  data() {
    return {
      page: 1,
      countByPage: 9,
    };
  },
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  computed: {
    ...mapGetters("content", ["getNews", "getNewsCategory"]),
    getCategoryRoute() {
      return this.$route.query.category;
    },
    getNewsByCategory() {
      if (!this.getCategoryRoute) return this.getNews;
      return filter(
        this.getNews,
        (news) => news.category?.id === parseInt(this.getCategoryRoute)
      );
    },
    getNewsByPage() {
      return filter(
        this.getNewsByCategory,
        (news, index) =>
          index >= (this.page - 1) * this.countByPage &&
          index < this.page * this.countByPage
      );
    },
    getCountNews() {
      return Number(this.getNewsByCategory.length);
    },
    getRoutePage() {
      return parseInt(this.$route.query?.page || 1);
    },
  },
  watch: {
    getRoutePage(val) {
      this.page = val || 1;
    },
  },
  mounted() {
    if (!this.getNews.length) {
      this.loadNews();
    }
    this.page = parseInt(this.$route.query?.page || 1);
  },
  methods: {
    ...mapActions("content", ["loadNews"]),
  },
};
</script>

<style scoped lang="scss"></style>
