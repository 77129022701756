<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <ul class="filter">
          <router-link
            class="filter__item"
            :to="{ name: $route.name, query: { category: link.id } }"
            v-for="link in types"
            :class="
              $route.query.category === link.id
                ? 'router-link-exact-active'
                : ''
            "
            :key="link.name"
            tag="li"
          >
            <a class="button">{{ link.name }}</a>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsFilter",
  props: {
    types: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.filter {
  margin: 0;
  padding: 20px 0 0;

  display: flex;
  list-style: none;
  @include media(768) {
    overflow: auto;
    padding-bottom: 10px;
  }

  &__item {
    margin-right: 24px;
    .button {
      padding: 0 25px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      color: #717579;
    }
    &.router-link-exact-active {
      .button {
        background: #ff6600;
        border: 1px solid #fe6500;
        color: #fff;
      }
    }
  }
}
</style>
